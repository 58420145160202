<template lang="pug">
  .top-bar.filters
    .row.align-items-center
      .filter
        AppIconButton.add-new(
          icon="plus-circle"
          title="plan_management.actions.add_new"
          @click="$emit('add-new')"
        )
      .filter
        AppSearch(
          :value="filters.searchValue"
          @update="applyFilter('searchValue', $event)"
        )
      .filter
        AppDatepicker(
          :value="filters.dateRange"
          range
          @change="applyFilter('dateRange', $event)"
        )
      .filter
        AppDropdown(
          allow-empty
          :value="filters.periodInclusion"
          :items="periodInclusions"
          @select="applyFilter('periodInclusion', $event)"
        )
      .filter
        AppIconButton.get-data-from-ota(
          title="plan_management.actions.get_data_from_ota"
          @click=""
          icon="reply"
          :use-app-icon="true"
          v-b-tooltip.hover="$t('inventory_management.tooltip')"
        )
      .filter
        AppSaveButton(
          disabled
          short
          title="plan_management.actions.save_changes"
          @save=""
        )
</template>

<script>
  import { PERIOD_INCLUSION_TYPES } from "./../constants.js"

  export default {
    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppSearch: () => import("@/components/elements/AppSearch"),
      AppDatepicker: () => import("@/components/elements/AppDatepicker"),
      AppSaveButton: () => import("@/components/elements/AppButton/Save"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other"),
      AppButton: () => import("@/components/elements/AppButton")
    },

    data() {
      return {
        periodInclusions: PERIOD_INCLUSION_TYPES
      }
    },

    props: {
      filters: {
        type: Object,
        default: () => new Object()
      }
    },

    methods: {
      applyFilter(itemType, items) {
        const filters = { ...this.filters, [itemType]: items }
        this.$emit("change-filters", filters)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .top-bar.filters
    +filter-title
    padding-left: 6px

    .filter
      padding-left: 10px

      .get-data-from-ota
        background-color: $default-purple
        ::v-deep
          .title
            color: $default-white

      .add-new
        background-color: $default-purple
        ::v-deep
          svg
            path
              fill: $default-white
          .title
            color: $default-white
</style>
